import { useKeyHandler, focusTrap } from "@/scripts";

const MenuToggleModule = (menuToggle: HTMLButtonElement) => {
  const navbar = document.querySelector(".navbar") as HTMLElement;
  const navbarFlyout = document.getElementById(
    "navbar-flyout"
  ) as HTMLDivElement;
  const headerMenuToggle = document.getElementById(
    "header-menu-toggle"
  ) as HTMLButtonElement;
  const navbarMenuToggle = document.getElementById(
    "navbar-menu-toggle"
  ) as HTMLButtonElement;
  const menuTabloopCloseBtn = document.querySelector(
    ".menu-toggle-tabloop"
  ) as HTMLButtonElement;

  // Handler for focus trap
  const handleFocusTrap = focusTrap(navbarFlyout);

  // Toggle open/closed state
  const toggle = (e: Event) => {
    const menuToggle = e.target as HTMLButtonElement;

    if (menuToggle.getAttribute("aria-expanded") === "false") {
      open();
    } else {
      close();
    }
  };

  // Close menu when click is outside of navbar and menuToggle
  const handleClickoutside = (e: Event) =>
    !navbarFlyout.contains(e.target as Node) &&
    e.target !== menuToggle &&
    close();

  const open = () => {
    if (navbarFlyout) {
      document.body.style.overflow = "hidden"; // Prevent body scroll
      document.body.classList.add("fade-overlay");

      menuToggle.setAttribute("aria-expanded", "true");
      menuToggle.focus();
      navbarFlyout.classList.add("flyout--visible");
      navbar.classList.add("navbar--reveal", "navbar--visible");

      scrollToActiveMenuItem();

      handleFocusTrap.activate();

      // Close menu when user hits Escape key
      useKeyHandler("Escape", close);
      // Close when user clicks outside of menu element
      window.addEventListener("click", handleClickoutside);
    }
  };

  const close = () => {
    if (navbarFlyout) {
      document.body.style.overflow = "auto"; // Allow body scroll
      document.body.classList.remove("fade-overlay");

      menuToggle.setAttribute("aria-expanded", "false");
      menuToggle.focus();

      navbarFlyout.classList.remove("flyout--visible");
      navbar.classList.remove("navbar--visible");

      if (document.documentElement.scrollTop === 0) {
        navbar.classList.remove("navbar--reveal");
      }
    }

    handleFocusTrap.deactivate();
    window.removeEventListener("click", handleClickoutside);
  };

  const scrollToActiveMenuItem = () => {
    const activeMenuItem = document.querySelector(".menu__item--active");
    const mq = window.matchMedia("(max-width: 1024px)");
    const navbarOffset = mq.matches ? navbar.offsetHeight : 0;

    if (activeMenuItem) {
      setTimeout(() => {
        const offsetTop = activeMenuItem.getBoundingClientRect().top;
        navbarFlyout.scrollTo(0, offsetTop - navbarOffset);
      }, 10);
    }
  };

  const init = () => {
    menuToggle.addEventListener("click", toggle);
    headerMenuToggle.addEventListener("click", (e) => {
      e.stopPropagation();
      navbarMenuToggle.click();
    });

    if (menuTabloopCloseBtn) {
      menuTabloopCloseBtn.addEventListener("click", (e) => {
        e.stopPropagation();
        navbarMenuToggle.click();
      });
    }
  };

  return {
    init,
    open,
  };
};

const initiateMenuToggles = () => {
  document.querySelectorAll(".menu-toggle").forEach((menuToggle) => {
    if (menuToggle.getAttribute("aria-controls") !== "navbar-menu-toggle") {
      MenuToggleModule(menuToggle as HTMLButtonElement).init();
    }
  });
};

export { MenuToggleModule, initiateMenuToggles };
